import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';

const Header = () => {
    
    const imageFolderPath = process.env.PUBLIC_URL + "/images/";
    const [logo, setLogo] = useState(true);

    function toggleLogo () {
        setLogo(logo => !logo);
    }

    return (
        <section className='logo'>

            {logo === true ? (
                <NavLink to='/' end onMouseEnter={toggleLogo}>
                    <img src={`${imageFolderPath}vl-logo.png`} alt="Site logo" className="logo"></img>
                </NavLink>
            ) : (
                <NavLink to='/' end onMouseLeave={toggleLogo}>
                    <img src={`${imageFolderPath}vl-favicon.png`} alt="Site Favicon" className="logo favicon"></img>
                </NavLink>
            )}
        </section>
    )

}

export default Header
