import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Hamburger = () => {
  const [menu, setMenu] = useState(true);

  function toggleMenu () {
    setMenu(menu => !menu);
  }

  return (
    <>

     <nav>

        {menu === false ? (
          <div className="menu-container" onClick={toggleMenu}>
            <div className="hamburger open">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="menu-container" onClick={toggleMenu}>
            <div className="hamburger not-open">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}

        <ul className={`menu-state ${menu === false ? 'open' : 'close'}`}>
            <li><Link to='/' end="false" onClick={toggleMenu} className='mobile-nav'>Home</Link></li>
            <li><Link to='/works' end="false" onClick={toggleMenu} className='mobile-nav'>Works</Link></li>
            <li><Link to='/about' end="false" onClick={toggleMenu} className='mobile-nav'>About</Link></li>
            <li><Link to='/contact' end="false" onClick={toggleMenu}
            className='mobile-nav'>Contact</Link></li>
        </ul>
    </nav>

    </>
  );
};
export default Hamburger;
