import { useState, useEffect } from 'react'
import Loading from '../components/Loading'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import "animate.css/animate.min.css"

const Work = () => {
    const { slug } = useParams();
    const restPath = `https://vanessaliang.ca/JsE83zCAYzWJduBR/wp-json/wp/v2/vl-work?_embed&slug=${slug}&acf_format=standard`
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath)
            if ( response.ok ) {
                const data = await response.json()
                setData(data[0])
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])

    AOS.init({
        duration: 1500,
        once: true,
    });
    
    return (
        <>
        { isLoaded ?
            <>
                <main id="content" className='work-wrapper'>

                    <header className='work-intro'>
                        {restData.acf && restData.acf.works_title && (
                            <div className='header-style work'>
                                <h1 className='title work'>{restData.acf.works_title}.</h1>
                            </div>
                        )}

                        {restData.acf && restData.acf.works_image.url && (
                            <img src={restData.acf.works_image.url} alt={restData.acf.works_image.alt} className='single-project-img'/>
                        )}

                        <div className='scroll-down' aria-label="Scroll down animation"></div>
                    </header>

                    <section className='overview'>
                        {restData.acf && restData.acf.project_overview && (
                        <div data-aos="fade-down">
                            <h2>Overview</h2>
                            <p>{restData.acf.project_overview}</p>
                        </div>
                        )}

                        <div className='roles-tools'>
                            <section className='roles' data-aos="fade-right">
                                {restData.acf && restData.acf.works_roles && (
                                <>
                                    <h3>Roles</h3>
                                    <p>{restData.acf.works_roles}</p>
                                </>
                                )}
                            </section>

                            <section className='tools' data-aos="fade-left">
                                {restData.acf && restData.acf.works_toolkit && (
                                <>
                                    <h3>Toolkit</h3>
                                    <p>{restData.acf.works_toolkit}</p>
                                </>
                                )}
                            </section>
                        </div>

                        <nav className='work-link' data-aos="fade-down">
                            {restData.acf && restData.acf.view_report && (
                                <a href={restData.acf.view_report.url}>View Report</a>
                            )}

                            {restData.acf && restData.acf.works_live && (
                                <a href={restData.acf.works_live}>View Live Site</a>
                            )}

                            {restData.acf && restData.acf.works_github && (
                                <a href={restData.acf.works_github}>View GitHub</a>
                            )}
                        </nav>
                    </section>
                        
                        {restData.acf && restData.acf.planning && (
                        <section className='planning' data-aos="fade-down">
                            <h2>Planning</h2>
                            <p>{restData.acf.planning}</p>
                                
                            {restData.acf && restData.acf.image_gallery[0] && restData.acf && restData.acf.image_gallery[1] && (
                            <div className='planning-img'>
                                <figure>
                                    <img src={restData.acf.image_gallery[0].url} alt={restData.acf.image_gallery[0].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.image_gallery[0].alt}</figcaption>
                                </figure>

                                <figure>
                                    <img src={restData.acf.image_gallery[1].url} alt={restData.acf.image_gallery[1].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.image_gallery[1].alt}</figcaption>
                                </figure>
                            </div>
                            )}
                        </section>
                        )}

                        {restData.acf && restData.acf.research && (
                            <section className='research' data-aos="fade-down">
                                <h2>Research</h2>
                                <p>{restData.acf.research}</p>
                            </section>
                            )}

                        {restData.acf && restData.acf.usability_tests && (
                            <section className='usability-tests' data-aos="fade-down">
                                <h2>Usability Tests</h2>
                                <p dangerouslySetInnerHTML={{__html:restData.acf.usability_tests}}></p>
                            </section>
                        )}

                        {restData.acf && restData.acf.works_design && (
                        <section className='design' data-aos="fade-down">
                            <h2>Design</h2>
                            <p>{restData.acf.works_design}</p>

                            {restData.acf && restData.acf.design_gallery[0] && restData.acf && restData.acf.design_gallery[1] && restData.acf && restData.acf.design_gallery[2] && (
                            <div className='design-gallery'>
                                <figure>
                                    <img src={restData.acf.design_gallery[0].url} alt={restData.acf.design_gallery[0].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.design_gallery[0].alt}</figcaption>
                                </figure>

                                <figure>
                                    <img src={restData.acf.design_gallery[1].url} alt={restData.acf.design_gallery[1].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.design_gallery[1].alt}</figcaption>
                                </figure>

                                <figure>
                                    <img src={restData.acf.design_gallery[2].url} alt={restData.acf.design_gallery[2].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.design_gallery[2].alt}</figcaption>
                                </figure>
                            </div>
                            )}
                        </section>
                        )}

                        {restData.acf && restData.acf.image_gallery_2[0] && restData.acf && restData.acf.image_gallery_2[1] && (
                            <section className='image-gallery' data-aos="fade-down">
                                <figure>
                                    <img src={restData.acf.image_gallery_2[0].url} alt={restData.acf.image_gallery_2[0].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.image_gallery_2[0].alt}</figcaption>
                                </figure>

                                <figure>
                                    <img src={restData.acf.image_gallery_2[1].url} alt={restData.acf.image_gallery_2[1].alt} loading="lazy"/>
                                    <figcaption>{restData.acf.image_gallery_2[1].alt}</figcaption>
                                </figure>
                            </section>
                        )}

                        {restData.acf && restData.acf.mockup && (
                            <section className='cta' data-aos="fade-down">
                                <a href={restData.acf.mockup.url} className='live-btn'>View Mockups</a>
                            </section>
                        )}

                        {restData.acf && restData.acf.tasks_design && (
                            <section className='tasks-design' data-aos="fade-down">
                                <h2>Tasks Design</h2>
                                <p dangerouslySetInnerHTML={{__html:restData.acf.tasks_design}}></p>
                            </section>
                        )}

                        {restData.acf && restData.acf.development && (
                            <section className='development' data-aos="fade-down">
                                <h2>Development</h2>
                                <p>{restData.acf.development}</p>
                            </section>
                        )}
                        
                        {restData.acf && restData.acf.game_feature_1 && (
                            <section className='game-features' data-aos="fade-down">
                                <h2>Game Features</h2>
                                <p dangerouslySetInnerHTML={{__html:restData.acf.game_feature_1}}></p>
                                <iframe height="300" style={{width: "100%"}} title="Breed Randomizer" src="https://codepen.io/vanessa-liang/embed/mdzoGbe?default-tab=js" loading="lazy" >
                                </iframe>
                            </section>
                        )}

                        {restData.acf && restData.acf.game_feature_2 && (
                            <section className='game-features' data-aos="fade-down">
                                <p dangerouslySetInnerHTML={{__html:restData.acf.game_feature_2}}></p>
                                <iframe height="300" style={{width: "100%"}} title="Keep Score and Check Answer" src="https://codepen.io/vanessa-liang/embed/oNaVPvK?default-tab=js" loading="lazy" >
                                </iframe>
                            </section>
                        )}

                        {restData.acf && restData.acf.works_wireframes && (
                            <section className='wireframes' data-aos="fade-down">
                                <h2>Wireframes</h2>
                                <div dangerouslySetInnerHTML={{__html:restData.acf.works_wireframes}}></div>

                                {restData.acf && restData.acf.wireframes && (
                                    <a href={restData.acf.wireframes} className='live-btn'>View Wireframes</a>
                                )}
                            </section>
                        )}

                        {restData.acf && restData.acf.special_features && (
                            <section className='first-feature' data-aos="fade-down">
                                <h2>Features</h2>
                                <p dangerouslySetInnerHTML={{__html:restData.acf.special_features}}></p>

                                {restData.acf && restData.acf.screen_record && (
                                <div>
                                    <video width="1920" height="1080" autoPlay muted loop>
                                        <source src={restData.acf.screen_record.url} type="video/mp4" loading="lazy"></source>
                                    </video>
                                </div>
                                )}
                            </section>
                        )}

                        {restData.acf && restData.acf.special_features_2 && (
                            <section className='second-feature' data-aos="fade-down">
                                <p dangerouslySetInnerHTML={{__html:restData.acf.special_features_2}}></p>

                                {restData.acf && restData.acf.code_snippet && (
                                    <section className='code-snippet'>
                                        <p dangerouslySetInnerHTML={{__html:restData.acf.code_snippet}}></p>
                                        <iframe height="300" style={{width: "100%"}} title="Untitled" src="https://codepen.io/vanessa-liang/embed/abRXVZj?default-tab=js" loading="lazy"></iframe>
                                    </section>
                                )}

                                {restData.acf && restData.acf.media && (
                                    <figure>
                                        <img src={restData.acf.media[0].url} alt={restData.acf.media[0].alt} loading="lazy"/>
                                    </figure>
                                )}
                            </section>
                        )}

                        {restData.acf && restData.acf.results && (
                            <section className='results' data-aos="fade-down">
                                <h2>Results</h2>
                                <p>{restData.acf.results}</p>

                                {restData.acf && restData.acf.results_gallery && restData.acf && restData.acf.results_gallery[0] && restData.acf && restData.acf.results_gallery[1] && (
                                <div className='results-imgs'>
                                    <figure className='fig1'>
                                        <img src={restData.acf.results_gallery[0].url} alt={restData.acf.results_gallery[0].alt} loading="lazy"/>
                                    </figure>

                                    <figure>
                                        <img src={restData.acf.results_gallery[1].url} alt={restData.acf.results_gallery[1].alt} loading="lazy"/>
                                    </figure>

                                    <figure>
                                        <img src={restData.acf.results_gallery[2].url} alt={restData.acf.results_gallery[2].alt} loading="lazy"/>
                                    </figure>
                                </div>
                                )}
                            </section>
                        )}

                        {restData.acf && restData.acf.takeaways && (
                            <section className='takeaways' data-aos="fade-down">
                                <h2>Takeaways</h2>
                                <p>{restData.acf.takeaways}</p>
                            </section>
                        )}


                    <section className='back'>
                        <Link to='/works'>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z"/></svg>
                            Back to Works
                        </Link>
                    </section>

                </main>
            </>
        : 
            <Loading />
        }
        </>
    )

}

export default Work
