import { useState, useEffect } from 'react'
import Loading from '../components/Loading'
import AOS from 'aos'
import 'aos/dist/aos.css'
import "animate.css/animate.min.css"

const About = () => {
    const restPath = `https://vanessaliang.ca/JsE83zCAYzWJduBR/wp-json/wp/v2/pages/10`
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath)
            if ( response.ok ) {
                const data = await response.json()
                setData(data)
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])

    AOS.init({
        duration: 1000,
        once: true,
    });
    
    return (
        <>
        { isLoaded ?

            <main id='content' className='about-wrapper'>

                <header className='header-style about'>
                    <h1 className='title'>{restData.title.rendered}.</h1>
                    <hr />
                </header>

                <section className='about-me' data-aos="fade-right">
                    <p>{restData.acf.about_me}</p>
                    <p>{restData.acf.hobbies}</p>
                </section>

                <section className='about-skills' data-aos="fade-right">
                    <h2>{restData.acf.skills_title}</h2>
                    <section dangerouslySetInnerHTML={{__html:restData.acf.development_skills}} data-aos="fade-right"></section>
                    <section dangerouslySetInnerHTML={{__html:restData.acf.design_skills}} data-aos="fade-left"></section>
                </section>

            </main>
        : 
            <Loading />
        }
        </>
    )
}

export default About
