import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../pages/Home'
import About from '../pages/About'
import Works from '../pages/Works'
import Contact from '../pages/Contact'
import Work from '../pages/Work'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../scss/styles.scss'
import Hamburger from '../components/Hamburger'
import SkipToContent from '../components/SkipToContent'

function AppRouter() {
    return (
      <BrowserRouter basename="/">
        <SkipToContent/>
        <Header/>
        <Hamburger/>
        <div className="site-wrapper">
        <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/works" element={<Works />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route path="/works/:slug" element={<Work />} />
        </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    );
  }
  export default AppRouter;