import { useState, useEffect } from 'react'
import Loading from '../components/Loading'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import "animate.css/animate.min.css";

const Works = () => {
    const restPath = `https://vanessaliang.ca/JsE83zCAYzWJduBR/wp-json/wp/v2/vl-work?order=desc&acf_format=standard`
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath)
            if ( response.ok ) {
                const data = await response.json()
                setData(data)
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])

    AOS.init({
        duration: 2000,
        once: true,
    });
    
    return (
        <>
        { isLoaded ?
            <>


            <main id='content'>

                <header>
                    <h1 className='title'>Works.</h1>
                </header>

                <div className='works-wrapper'>

                    {restData.map(post => 

                        <article key={post.id} className={post.slug}>
                            <div className='all-works' data-aos="fade-up">
                                <div className='works-container'>
                                    <img
                                    src={post.acf.project_img.url} alt={post.acf.project_img.alt} loading="lazy"/>
                                </div>

                                <div className='works-hover' id={`${post.slug}-hover`}>
                                    <h2>{post.title.rendered}</h2>
                                    <p>{post.acf.overview}</p>
                                    <Link to={`/works/${post.slug}`} className='view-project'>
                                        <svg>
                                            <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                                        </svg>
                                        View Project
                                    </Link>
                                </div>
                            </div>
                        </article>
                    )}
                </div>

            </main>

            </>
        : 
            <Loading />
        }
        </>
    )

}

export default Works
