import {getYear} from '../utilities/getYear'

const Footer = ({copyrightYear}) => {

    return (
        <p className="copyright">&copy; Vanessa Liang {copyrightYear} </p>
    )

}

Footer.defaultProps = {
    copyrightYear: getYear(),
  };

export default Footer
